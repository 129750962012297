import React from 'react';
import './SpinnerOverlay.scss'; // For custom overlay styles
import CustomSpinnerIcon from './CustomSpinnerIcon';


const SpinnerOverlay: React.FC = () => {
    return (
        <div className="spinner-overlay">
            <CustomSpinnerIcon />
        </div>
    );
};

export default SpinnerOverlay;
