import { getToken } from "firebase/messaging"
import { apiBaseUrl } from "../../configs/app"
import loginService from "../loginService"
import fcmService from "../fcm.service"

const messengeBaseUrl = apiBaseUrl + "messenger/"
export function fetchConversations() {
    return loginService.privateGetApiCall(messengeBaseUrl + "conversation/all")
}
export function updateTitle(conversation_id: number, title: string) {
    return loginService.privatePutApiCall(messengeBaseUrl + "conversation/update/title", {
        conversation_id,
        title
    })
}
export function deleteConversation(conversation_id: number) {
    return loginService.privateApiRequest('delete', messengeBaseUrl + "conversation/delete", {
        conversation_id
    })
}
export function fetchConversation(id: number) {
    return loginService.privateGetApiCall(messengeBaseUrl + "message/all/" + id)
}
export function sendMessage(conversation_id: number, message: string) {
    return loginService.privatePutApiCall(messengeBaseUrl + "message/send", {
        conversation_id,
        message
    })
}
export function sendImage(conversation_id: number, imageString: string) {
    return loginService.privatePutApiCall(messengeBaseUrl + "message/send-image", {
        conversation_id,
        imageString
    })
}
export function sendNewMessage(receiver_id: number, conversation_name: string, message: string) {
    return loginService.privatePutApiCall(messengeBaseUrl + "message/new", {
        receiver_id,
        conversation_name,
        message
    })
}
export function unseenCount(){
    return loginService.privateGetApiCall(messengeBaseUrl + "unseen-count")
}

export async function requestFCMPermission() {
    try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            // console.log('Notification permission granted.');
            const token = fcmService.requestToken();
            if (token) {
                loginService.privatePutApiCall(apiBaseUrl + "update-fcm-token", {
                    fcm_token: token
                }).then(() => {
                    // console.log("FCM Token Updated")
                }).catch((err) => {
                    console.error("FCM Token Update Failed", err)
                })
                // console.log('FCM Token:', token);
            } else {
                console.error('No registration token available. Request permission to generate one.');
            }
        } else {
            console.error('Unable to get permission to notify.');
        }
    } catch (error) {
        console.error('An error occurred while retrieving token.', error);
    }
};
