import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { appSubDir } from '../configs/app';
import SpinnerOverlay from '../components/SpinnerOverlay';

// Lazy-loaded components
const Home = React.lazy(() => import('../pages/Home'));
const About = React.lazy(() => import('../pages/About'));
const Contact = React.lazy(() => import('../pages/Contact'));
const Shop = React.lazy(() => import('../pages/products/Shop'));
const FlashSale = React.lazy(() => import('../pages/products/FlashSale'));
const NewArrival = React.lazy(() => import('../pages/products/NewArrival'));
const Product = React.lazy(() => import('../pages/products/Product'));
const Search = React.lazy(() => import('../pages/products/Search'));
const Category = React.lazy(() => import('../pages/products/Category'));
const Brand = React.lazy(() => import('../pages/products/Brand'));
const SubCategory = React.lazy(() => import('../pages/products/SubCategory'));
const PaymentVerify = React.lazy(() => import('../pages/payments/PaymentVerify'));
const OrderPlaced = React.lazy(() => import('../pages/payments/OrderPlaced'));
const PaymentSuccess = React.lazy(() => import('../pages/payments/PaymentSuccess'));
const PaymentFailed = React.lazy(() => import('../pages/payments/PaymentFailed'));
const PaymentCancelled = React.lazy(() => import('../pages/payments/PaymentCancelled'));
const GuestUserInvoice = React.lazy(() => import('../pages/GuestUserInvoice'));
const ResellerRegistration = React.lazy(() => import('../pages/ResellerRegistration'));
const PrivacyPolicy = React.lazy(() => import('../pages/kegal/PrivacyPolicy'));
const TermsContitions = React.lazy(() => import('../pages/kegal/TermsContitions'));
const NotFound = React.lazy(() => import('../pages/NotFound'));


const PublicRoutes: React.FC = () => (
    <IonReactRouter>
        <Suspense fallback={<SpinnerOverlay />}>
            <IonRouterOutlet>
                <Route exact path={appSubDir}>
                    <Home />
                </Route>
                <Route exact path={appSubDir + "about"}>
                    <About />
                </Route>
                <Route exact path={appSubDir + "contact"}>
                    <Contact />
                </Route>
                <Route exact path={appSubDir + "shop"}>
                    <Shop />
                </Route>
                <Route exact path={appSubDir + "flash-sale"}>
                    <FlashSale />
                </Route>
                <Route exact path={appSubDir + "new-arrival"}>
                    <NewArrival />
                </Route>
                <Route exact path={appSubDir + "product/:slug/item-:ID"}>
                    <Product />
                </Route>
                <Route exact path={appSubDir + "search/:searchKey"}>
                    <Search />
                </Route>
                <Route exact path={appSubDir + "categories/:catID"}>
                    <Category />
                </Route>
                <Route exact path={appSubDir + "brands/:brandID"}>
                    <Brand />
                </Route>
                <Route exact path={appSubDir + "categories/:catID/sub-categories/:subCatID"}>
                    <SubCategory />
                </Route>
                <Route exact path={appSubDir + "payment-verify/:valID"}>
                    <PaymentVerify />
                </Route>
                <Route exact path={appSubDir + "order-placed"}>
                    <OrderPlaced />
                </Route>
                <Route exact path={appSubDir + "payment/success"}>
                    <PaymentSuccess />
                </Route>
                <Route exact path={appSubDir + "payment/failed"}>
                    <PaymentFailed />
                </Route>
                <Route exact path={appSubDir + "payment/cancelled"}>
                    <PaymentCancelled />
                </Route>
                <Route exact path={appSubDir + "guest-invoice/:encryptedInvoiceID"}>
                    <GuestUserInvoice />
                </Route>
                <Route exact path={appSubDir + "reseller-registration"}>
                    <ResellerRegistration />
                </Route>
                <Route exact path={appSubDir + "privacy-policy"}>
                    <PrivacyPolicy />
                </Route>
                <Route exact path={appSubDir + "terms-and-conditions"}>
                    <TermsContitions />
                </Route>
                <Route>
                    <NotFound />
                </Route>
            </IonRouterOutlet>
        </Suspense>
    </IonReactRouter>
);

export default PublicRoutes;
