import { apiBaseUrl } from "../configs/app";


function convertBlobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onerror = reject
        reader.onload = () => {
            resolve(reader.result)
        }
        reader.readAsDataURL(blob)
    })
}
export async function storeImg(fileName: string, token: string) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const requestOptions = {
        method: 'GET',
        cache: "no-store",
        headers: myHeaders,
        redirect: 'follow'
    };
    const url = apiBaseUrl + "image?url=" + fileName
    const response = await fetch(url, requestOptions as RequestInit)
    const blob = await response.blob()
    const base64Data = await convertBlobToBase64(blob) as string
    localStorageSafeSetItem(fileName, base64Data)
}

export function localStorageSafeSetItem(key: string, value: string): void {
    try {
        localStorage.setItem(key, value);
    } catch (error: unknown) {
        if (error instanceof DOMException && error.name === 'QuotaExceededError') {
            console.warn('Attempting to clean up localStorage...');
            // Example: Clear old entries or apply a custom cleanup strategy
            localStorage.clear()
            localStorageSafeSetItem(key, value);
        }
    }
}
