import React from 'react';

const CustomSpinnerIcon: React.FC = () => (
    <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <circle cx="50" cy="10" r="4" fill="#DFC31F" />
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 50 50"
                to="360 50 50"
                dur="1.5s"
                repeatCount="indefinite"
            />
        </g>
        <g>
            <circle cx="70" cy="15" r="6" fill="#F37D2F" />
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 50 50"
                to="360 50 50"
                dur="1.5s"
                repeatCount="indefinite"
            />
        </g>
        <g>
            <circle cx="90" cy="30" r="8" fill="#E62227" />
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 50 50"
                to="360 50 50"
                dur="1.5s"
                repeatCount="indefinite"
            />
        </g>
        <g>
            <circle cx="50" cy="90" r="4" fill="#2E86AB" />
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 50 50"
                to="360 50 50"
                dur="1.5s"
                repeatCount="indefinite"
            />
        </g>
        <g>
            <circle cx="30" cy="85" r="6" fill="#6A4C93" />
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 50 50"
                to="360 50 50"
                dur="1.5s"
                repeatCount="indefinite"
            />
        </g>
        <g>
            <circle cx="10" cy="70" r="8" fill="#1ABC9C" />
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 50 50"
                to="360 50 50"
                dur="1.5s"
                repeatCount="indefinite"
            />
        </g>
        <image
            href="/icon-192x192.webp"
            x="20%"
            y="20%"
            height="60"
            width="60"
            preserveAspectRatio="xMidYMid meet"
        />
    </svg>
);

export default CustomSpinnerIcon;
