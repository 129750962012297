import { isPlatform } from '@ionic/react';
import { FCM as CapacitorFCM } from '@capacitor-community/fcm';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { PushNotifications } from '@capacitor/push-notifications';
import { firebaseConfig, vapidKey } from '../configs/app';
import { PluginListenerHandle } from '@capacitor/core';


// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Capacitor FCM plugin only if running on a Capacitor platform
const fcm = isPlatform('capacitor') ? CapacitorFCM : null;

// Initialize Firebase Messaging for web
const messaging = isPlatform('capacitor') ? null : getMessaging(app);

export type NormalizedMessage = {
    title: string;
    body: string;
    data: Record<string, any>;
    image?: string | null; // Optional, as some notifications may not have an image
};
function serializeMessage(message: any): NormalizedMessage {
    if ('notification' in message) {
        // Web payload
        return {
            title: message.notification.title || '',
            body: message.notification.body || '',
            data: message.data || {},
            image: message.notification.image || null
        };
    } else {
        // Capacitor notification
        return {
            title: message.title || '',
            body: message.body || '',
            data: message.data || {},
            image: null // Capacitor notifications don't include an image field
        };
    }
}


class FCMService {
    /**
     * Request permission and get FCM token
     */
    async requestToken(): Promise<string | null> {
        try {
            if (isPlatform('capacitor')) {
                if (fcm) {
                    const { token } = await fcm.getToken();
                    // console.log('FCM Token (Android):', token);
                    return token;
                } else {
                    console.error('requestToken failed ! FCM Plugin not available');
                    return null;
                }
            } else {
                if (messaging) {
                    const token = await getToken(messaging, { vapidKey: vapidKey });
                    // console.log('FCM Token (Web):', token);
                    return token;
                } else {
                    console.error('requestToken failed ! messaging not available');
                    return null;
                }
            }
        } catch (error) {
            console.error('Error fetching FCM token:', error);
            return null;
        }
    }
    /**
     * Listen for FCM messages with a callback
     * @param callback - Function to handle incoming notifications or payloads
     * @returns A function to unsubscribe from the listener
     */
    listenForMessages(callback: (message: NormalizedMessage) => void): () => void {
        if (isPlatform('capacitor')) {
            let listenerHandle: PluginListenerHandle | null = null;

            // Register the listener
            PushNotifications.addListener('pushNotificationReceived', (notification) => {
                console.log('Push notification received:', notification);
                const serializedMessage = serializeMessage(notification);
                if (callback) {
                    callback(serializedMessage);
                }
            }).then((handle) => {
                listenerHandle = handle;
            });

            // Return the unsubscribe function
            return () => {
                if (listenerHandle) {
                    listenerHandle.remove();
                    console.log('Unsubscribed from Capacitor push notification listener.');
                }
            };
        } else {
            if (messaging) {
                const unsubscribe = onMessage(messaging, (payload) => {
                    console.log('Message received (Web):', payload);
                    const serializedMessage = serializeMessage(payload);
                    if (callback) {
                        callback(serializedMessage);
                    }
                });

                // Return the unsubscribe function
                return () => {
                    unsubscribe();
                    console.log('Unsubscribed from Firebase messaging listener.');
                };
            } else {
                console.error('messaging not available');
                // Return a no-op function since there's no listener to unsubscribe
                return () => { };
            }
        }
    }


    /**
     * Subscribe to a topic (Android only)
     */
    async subscribeToTopic(topic: string): Promise<void> {
        if (isPlatform('capacitor') && fcm) {
            try {
                await fcm.subscribeTo({ topic });
                console.log(`Subscribed to topic: ${topic}`);
            } catch (error) {
                console.error('Error subscribing to topic:', error);
            }
        } else {
            console.warn('Topic subscriptions are not supported on the web.');
        }
    }

    /**
     * Unsubscribe from a topic (Android only)
     */
    async unsubscribeFromTopic(topic: string): Promise<void> {
        if (isPlatform('capacitor') && fcm) {
            try {
                await fcm.unsubscribeFrom({ topic });
                console.log(`Unsubscribed from topic: ${topic}`);
            } catch (error) {
                console.error('Error unsubscribing from topic:', error);
            }
        } else {
            console.warn('Topic unsubscriptions are not supported on the web.');
        }
    }
}

const fcmService = new FCMService();
export default fcmService;
