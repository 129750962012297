import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import {
    IonRouterOutlet
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { appSubDir } from '../configs/app';
import { useAppContext } from '../services/app-context.service';
import SpinnerOverlay from '../components/SpinnerOverlay';

// Lazy-loaded components
const Home = React.lazy(() => import('../pages/Home'));
const About = React.lazy(() => import('../pages/About'));
const Contact = React.lazy(() => import('../pages/Contact'));
const Shop = React.lazy(() => import('../pages/products/Shop'));
const FlashSale = React.lazy(() => import('../pages/products/FlashSale'));
const Product = React.lazy(() => import('../pages/products/Product'));
const Category = React.lazy(() => import('../pages/products/Category'));
const Brand = React.lazy(() => import('../pages/products/Brand'));
const SubCategory = React.lazy(() => import('../pages/products/SubCategory'));
const NotFound = React.lazy(() => import('../pages/NotFound'));
const NewArrival = React.lazy(() => import('../pages/products/NewArrival'));
const GuestInvoiceLoginError = React.lazy(() => import('../pages/GuestInvoiceLoginError'));
const Invoices = React.lazy(() => import('../pages/account/Invoices'));
const Invoice = React.lazy(() => import('../pages/account/Invoice'));
const PaymentVerify = React.lazy(() => import('../pages/payments/PaymentVerify'));
const PaymentSuccess = React.lazy(() => import('../pages/payments/PaymentSuccess'));
const PaymentFailed = React.lazy(() => import('../pages/payments/PaymentFailed'));
const PaymentCancelled = React.lazy(() => import('../pages/payments/PaymentCancelled'));
const PaymentScreen = React.lazy(() => import('../pages/payments/PaymentScreen'));
const TransactionsPage = React.lazy(() => import('../pages/account/TransactionsPage'));
const TransactionsDetailsPage = React.lazy(() => import('../pages/account/TransactionsDetailsPage'));
const GroupLinks = React.lazy(() => import('../pages/account/GroupLinks'));
const WishlistPage = React.lazy(() => import('../pages/products/WishlistPage'));
const ResellerDashboard = React.lazy(() => import('../pages/account/ResellerDashboard'));
const Search = React.lazy(() => import('../pages/products/Search'));
const Conversations = React.lazy(() => import('../pages/messages/Conversations'));
const ConversationPage = React.lazy(() => import('../pages/messages/ConversationPage'));
const CreateConversation = React.lazy(() => import('../pages/messages/CreateConversation'));
const ReferralDashboard = React.lazy(() => import('../pages/account/ReferralDashboard'));
const MyProfile = React.lazy(() => import('../pages/account/MyProfile'));

const PrivateRoutes: React.FC = () => {
    const { user } = useAppContext();

    return (
        <IonReactRouter>
            <Suspense fallback={<SpinnerOverlay />}>
                <IonRouterOutlet>
                    {/* Public Pages */}
                    <Route exact path={appSubDir} component={Home} />
                    <Route exact path={appSubDir + "about"} component={About} />
                    <Route exact path={appSubDir + "contact"} component={Contact} />
                    <Route exact path={appSubDir + "shop"} component={Shop} />
                    <Route exact path={appSubDir + "flash-sale"} component={FlashSale} />
                    <Route exact path={appSubDir + "new-arrival"} component={NewArrival} />
                    <Route exact path={appSubDir + "product/:slug/item-:ID"} component={Product} />
                    <Route exact path={appSubDir + "search/:searchKey"} component={Search} />
                    <Route exact path={appSubDir + "categories/:catID"} component={Category} />
                    <Route exact path={appSubDir + "brands/:brandID"} component={Brand} />
                    <Route exact path={appSubDir + "categories/:catID/sub-categories/:subCatID"} component={SubCategory} />
                    <Route exact path={appSubDir + "guest-invoice/:encryptedInvoiceID"} component={GuestInvoiceLoginError} />
                    <Route exact path={appSubDir + "invoices"} component={Invoices} />
                    <Route exact path={appSubDir + "invoices/:invoiceID"} component={Invoice} />
                    <Route exact path={appSubDir + "transactions"} component={TransactionsPage} />
                    <Route exact path={appSubDir + "my-shop"} component={WishlistPage} />
                    <Route exact path={appSubDir + "wishlist"} component={WishlistPage} />
                    <Route exact path={appSubDir + "fb-group-links"} component={GroupLinks} />
                    <Route exact path={appSubDir + "transactions/:date"} component={TransactionsDetailsPage} />
                    {/* Private Pages */}
                    <Route exact path={appSubDir + "my-profile"} component={MyProfile} />
                    <Route exact path={appSubDir + "conversations"} component={Conversations} />
                    <Route exact path={appSubDir + "conversations/:id"} component={ConversationPage} />
                    <Route exact path={appSubDir + "create-conversation"} component={CreateConversation} />
                    <Route exact path={appSubDir + "payment-verify/:valID"} component={PaymentVerify} />
                    <Route exact path={appSubDir + "payment/success"} component={PaymentSuccess} />
                    <Route exact path={appSubDir + "payment/failed"} component={PaymentFailed} />
                    <Route exact path={appSubDir + "payment/cancelled"} component={PaymentCancelled} />
                    <Route exact path={appSubDir + "secure-payment"} component={PaymentScreen} />
                    <Route
                        exact
                        path={appSubDir + "reseller-dashboard"}
                        render={() => (user.role_id === 2 ? <ResellerDashboard /> : <NotFound />)}
                    />
                    <Route
                        exact
                        path={appSubDir + "referral-dashboard"}
                        render={() => (user.role_id === 2 ? <ReferralDashboard /> : <NotFound />)}
                    />
                    <Route component={NotFound} />
                </IonRouterOutlet>
            </Suspense>
        </IonReactRouter>
    );
};

export default PrivateRoutes;
