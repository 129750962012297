/**
 * File Name    : Service - Shopping Cart Service
 * Desctipn     : This file will provide necessary service to perform all the cart action and functions.
 * Author       : MD. Mashfiqur Rahman
 * Website      : https://mashfiqnahid.com
 * Email        : mashfiqnahid@gmail.com
 */

import axios from 'axios';
import { apiBaseUrl, apiConfig } from '../../configs/app';
import Cart, { CartItem, Discount, ShippingAddress } from '../../types/shop/cart.type';
import CryptoService from '../crypto.services';
import loginService from '../loginService';
import { trackEvent } from '../tracking.service';
import { isPlatform } from '@ionic/react';
import { localStorageSafeSetItem } from '../cache.services';
export const defaultCart = {
    items: [] as CartItem[],
    itemCount: 0,
    subTotal: 0,
    shippingAddress: {
        name: "",
        phone: "",
        address: "",
        note: ""
    } as ShippingAddress,
    totalDeliveryCharge: 60,
    paidUnpaid: "Unpaid",
    promoCode: '',
    totalDiscount: 0,
    total: 0,
    commissionSubTotal: 0,
    totalResellerCommission: 0,
    paymentType: 'Cash On Delivery',
    paymentStatus: 'Pending',
    val_id: '',
    currency: 'BDT',
    ssl_status: '',
    payment_details: '',

    saveShippingDetails: false
} as Cart
const shoppingCartKey = CryptoService.hash('shopping-cart-key')
export class ShoppingCartService {
    debugMode = true
    shoppingCart = this.getLocalCartDetails() || defaultCart

    saveCart() {
        this.getTotalResellerCommission()
        this.shoppingCart.subTotal = this.getSubTotal()
        this.shoppingCart.itemCount = this.getItemCount()
        this.shoppingCart.total = this.getTotal()
        localStorageSafeSetItem(shoppingCartKey, CryptoService.encrypt(JSON.stringify(this.shoppingCart)))
    }

    getItemIndex(cartItem: CartItem) {
        return this.shoppingCart.items.findIndex((item) => (
            item.id === cartItem.id &&
            item.selected_color === cartItem.selected_color &&
            item.selected_size === cartItem.selected_size
        ))
    }
    getItemCount() {
        var result = 0
        this.shoppingCart.items.map((item, index) => {
            result += item.quantity
        })
        this.shoppingCart.itemCount = result
        return result
    }
    getSubTotal() {
        var result = 0
        this.shoppingCart.items.map((item, index) => {
            result += item.itemTotal
        })
        this.shoppingCart.subTotal = result
        return result
    }
    getTotalResellerCommission() {
        this.shoppingCart.totalResellerCommission = 0
        this.shoppingCart.items.map((item, index) => {
            this.shoppingCart.totalResellerCommission += (item.quantity * (item.sale_price - item.min_sale_price))
        })
        this.shoppingCart.commissionSubTotal = this.shoppingCart.totalResellerCommission
        this.shoppingCart.totalResellerCommission += this.shoppingCart.totalDiscount
        if (this.shoppingCart.paidUnpaid == "Paid") {
            this.shoppingCart.totalResellerCommission -= this.shoppingCart.totalDeliveryCharge
        }
        return this.shoppingCart.totalResellerCommission
    }
    getTotal() {
        if (this.shoppingCart.paidUnpaid == "Paid") {
            this.shoppingCart.total = this.shoppingCart.subTotal - this.shoppingCart.totalDiscount
        } else {
            this.shoppingCart.total = this.shoppingCart.subTotal + this.shoppingCart.totalDeliveryCharge - this.shoppingCart.totalDiscount
        }
        return this.shoppingCart.total
    }
    updateSatePrice(cartItem: CartItem, sale_price: number) {
        this.shoppingCart = this.getCartDetails()
        var itemIndex = this.getItemIndex(cartItem)
        if (itemIndex >= 0) {
            const productInCart = this.shoppingCart.items[itemIndex]
            if (sale_price >= productInCart.min_sale_price) {
                productInCart.sale_price = sale_price
            } else {
                productInCart.sale_price = productInCart.min_sale_price
            }
            productInCart.itemTotal = productInCart.quantity * productInCart.sale_price
            this.shoppingCart.items[itemIndex] = productInCart
            this.saveCart()
            return productInCart
        }
        return false
    }
    updateQuantity(itemIndex: number, quantity: number) {
        if (quantity < 1) {
            return
        }
        const productInCart = this.shoppingCart.items[itemIndex]
        productInCart.quantity = quantity
        productInCart.itemTotal = productInCart.quantity * productInCart.sale_price
        this.shoppingCart.items[itemIndex] = productInCart
        this.saveCart()
    }
    addToCart(cartItem: CartItem, quantity = 1, set = false) {
        var itemIndex = this.getItemIndex(cartItem)
        if (itemIndex >= 0) {
            const productInCart = this.shoppingCart.items[itemIndex]
            if (set && quantity > 0) {
                productInCart.quantity = quantity
            } else {
                productInCart.quantity += quantity
            }
            productInCart.sale_price = cartItem.sale_price
            productInCart.min_sale_price = cartItem.sale_price
            productInCart.itemTotal = productInCart.quantity * productInCart.sale_price
            this.shoppingCart.items[itemIndex] = productInCart
            trackEvent('AddToCart', {
                content_name: productInCart.name,
                content_category: productInCart.category_title,
                content_ids: [productInCart.id],
                content_type: 'product',
                unit_price: productInCart.sale_price,
                quantity: productInCart.quantity,
                item_total: productInCart.itemTotal,
                currency: 'BDT'
            });
        } else {
            cartItem.sale_price = cartItem.sale_price
            cartItem.min_sale_price = cartItem.sale_price
            cartItem.quantity = quantity
            cartItem.itemTotal = cartItem.quantity * cartItem.sale_price
            this.shoppingCart.items.push(cartItem)

            trackEvent('AddToCart', {
                content_name: cartItem.name,
                content_category: cartItem.category_title,
                content_ids: [cartItem.id],
                content_type: 'product',
                unit_price: cartItem.sale_price,
                quantity: cartItem.quantity,
                item_total: cartItem.itemTotal,
                currency: 'BDT'
            });
        }
        this.saveCart()
        return this.shoppingCart
    }
    removeFromCart(itemIndex: number, quantity = 1) {
        if (itemIndex >= 0 && this.shoppingCart.items[itemIndex].quantity > quantity) {
            const cartItem = this.shoppingCart.items[itemIndex]
            cartItem.quantity -= quantity
            this.saveCart()
        } else {
            this.removeItemFromCart(itemIndex)
        }
    }
    removeItemFromCart(itemIndex: number) {
        if (itemIndex >= 0) {
            this.shoppingCart.items.splice(itemIndex, 1)
            this.saveCart()
        }
    }
    getLocalCartDetails() {
        const encryptedshoppingCartString = window.localStorage.getItem(shoppingCartKey)
        if (encryptedshoppingCartString) {
            return JSON.parse(CryptoService.decrypt(encryptedshoppingCartString)) as Cart
        }
        return false
    }
    getCartDetails() {
        const encryptedshoppingCartString = window.localStorage.getItem(shoppingCartKey)
        if (encryptedshoppingCartString) {
            this.shoppingCart = JSON.parse(CryptoService.decrypt(encryptedshoppingCartString))
        }
        return this.shoppingCart
    }
    updateShippgingAddress(shippingAddress: ShippingAddress) {
        this.shoppingCart = this.getCartDetails()
        this.shoppingCart.shippingAddress = shippingAddress
        this.saveCart()
        return this.shoppingCart
    }
    async applyPromoCode(promoCode: string) {
        return loginService.privateApiCall(apiConfig.getDiscount, { promoCode }).then((res) => {
            let totalDiscount
            const discount = res.data as Discount
            if (discount.percent) {
                totalDiscount = this.shoppingCart.subTotal * discount.percent / 100
            } else {
                totalDiscount = discount.rate
            }
            if (totalDiscount) {
                this.shoppingCart = this.getCartDetails()
                this.shoppingCart.promoCode = promoCode
                this.shoppingCart.totalDiscount = Math.min(totalDiscount, this.shoppingCart.subTotal)
                this.getTotal()
                this.saveCart()
            }
            return this.shoppingCart
        })
    }
    removePromoCode() {
        if (this.shoppingCart.promoCode) {
            this.shoppingCart.promoCode = ''
            this.shoppingCart.totalDiscount = 0
            this.getTotal()
            this.saveCart()
        }
        return this.shoppingCart
    }

    makePostReady(object: any) {
        let sentence = "";
        for (var key in object) {
            sentence = sentence + key + '=' + object[key] + '&';
        }
        //console.log( 'sentence: ' + sentence );
        return sentence;
    }
    requestPaymentUrl(otp: string, payload: string) {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({ total: this.shoppingCart.total, otp, payload });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        } as RequestInit;

        return fetch(apiBaseUrl + "guest/payment-url", requestOptions)
            .then(response => {
                if (response.status == 200) {
                    return response.json()
                } else {
                    throw response.json()
                }
            })
    }
    placeGuestSslOrder(val_id: string) {
        const dataToPost = { ...this.shoppingCart, val_id };
        return axios.post(apiBaseUrl + 'guest/place-ssl-order', dataToPost, {
            headers: {
                'Content-Type': "application/json",
                'Accept': "application/json"
            }
        })
    }
    placeSslOrder(val_id: string) {
        if (!loginService.currentUser.token) {
            return this.placeGuestSslOrder(val_id)
        }

        return this.makeOrder(val_id)
    }
    getPaymentUrl() {
        const dataToPost = {
            total: this.shoppingCart.total,
            mobile_platform: isPlatform('capacitor')
        };
        return loginService.privateApiCall(apiBaseUrl + "get-payment-url", dataToPost)
    }
    getGuestPaymentUrl(otp: string, payload: string) {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ total: this.shoppingCart.total, otp, payload });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        } as RequestInit;

        return fetch(apiBaseUrl + "guest/payment-url", requestOptions)
            .then(response => {
                if (response.status == 200) {
                    return response.json()
                } else {
                    throw response.json()
                }
            })
    }
    placeGuestOrder(otp: string, payload: string) {
        if (this.shoppingCart.paymentType == 'sslcommerz') {
            return this.getGuestPaymentUrl(otp, payload)
        }
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ ...this.shoppingCart, otp, payload });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        } as RequestInit;

        return fetch(apiBaseUrl + "guest/place-order", requestOptions)
            .then(response => {
                if (response.status == 200) {
                    return response.json()
                } else {
                    throw response.json()
                }
            })
    }
    makeOrder(val_id: string = "") {
        if (this.shoppingCart.paymentType == 'sslcommerz' && !val_id) {
            return this.getPaymentUrl()
        }
        const items = [] as any
        this.shoppingCart.items.map((item, index) => {
            items.push({
                id: item.id,
                sale_price: item.sale_price,
                quantity: item.quantity,
                color: item.selected_color,
                size: item.selected_size,
            })
        })
        const dataToPost = {
            items: JSON.stringify(items),
            shippingAddressName: this.shoppingCart.shippingAddress.name,
            shippingAddressPhone: this.shoppingCart.shippingAddress.phone,
            shippingAddressAddress: this.shoppingCart.shippingAddress.address,
            shippingAddressNote: this.shoppingCart.shippingAddress.note,
            promoCode: this.shoppingCart.promoCode,
            totalDeliveryCharge: this.shoppingCart.totalDeliveryCharge,
            shipping_cost_reason: this.shoppingCart.shippingCost?.reason,
            paidUnpaid: this.shoppingCart.paidUnpaid,
            paymentType: this.shoppingCart.paymentType,
            paymentStatus: this.shoppingCart.paymentStatus,
            val_id,
            currency: this.shoppingCart.currency,
            payment_details: JSON.stringify(this.shoppingCart.payment_details)
        }
        return loginService.privateApiCall(apiConfig.placeOrder, dataToPost)
    }
    verifyStock() {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ items: this.shoppingCart.items });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        } as RequestInit;

        return fetch(apiBaseUrl + 'verify-stock', requestOptions).then(response => {
            if (response.status == 200) {
                return response.text()
            } else {
                throw response.text()
            }
        })
    }
    clearCart() {
        const shippingAddress = this.shoppingCart.shippingAddress
        const shippingCost = this.shoppingCart.shippingCost
        const saveShippingDetails = this.shoppingCart.saveShippingDetails
        this.shoppingCart = defaultCart
        if (saveShippingDetails) {
            this.shoppingCart.shippingAddress = shippingAddress
            this.shoppingCart.shippingCost = shippingCost
        }
        this.saveCart()
    }

}
const shoppingCartService = new ShoppingCartService()
export default shoppingCartService
